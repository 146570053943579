import * as translations from '../translations';
import channelsQuery from './ChannelsQuery.gql';
import createFacebookTracker from '@jetshop/core/server/tracking/facebook';
import routeQuery from './components/RouteQuery.gql';
import ProductPage from './components/ProductPage/ProductPage.loadable';
import { LoadableCategoryPage } from './components/Shop';
import { theme } from './components/Theme';
import { persistedQueries } from './persistedQueries';

const config = {
  theme,
  apolloConfig: {
    shopid: process.env.REACT_APP_SHOP_ID || 'finntack',
    graphQLURI:
      process.env.REACT_APP_GRAPHQL_URI || 'https://storeapi.jetshop.io',
    token:
      process.env.REACT_APP_APOLLO_TOKEN ||
      '359fd7c1-8e72-4270-b899-2bda9ae6ef57',
    engineApiKey: process.env.ENGINE_API_KEY || '',
    enableGateway: false,
    channelsQuery,
    persistedQueries: persistedQueries
  },
  trackingID: process.env.REACT_APP_GA_TRACKING_ID,
  additionalGtagTrackingIds: [],
  tagManagerID: process.env.REACT_APP_GTM_CONTAINER_ID,
  relewareEnabled: true,
  intl: {
    translations,
    defaultLocale: 'en',
    options: {
      formats: {
        number: {
          EUR: { style: 'currency', currency: 'EUR' },
          SEK: {
            style: 'currency',
            currency: 'SEK',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          },
          USD: { style: 'currency', currency: 'USD' },
          GBP: { style: 'currency', currency: 'GBP' },
          DKK: {
            style: 'currency',
            currency: 'DKK',
            currencyDisplay: 'code',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          },
          NOK: {
            style: 'currency',
            currency: 'NOK',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          },
          AUD: { style: 'currency', currency: 'USD' }
        }
      }
    }
  },
  channelOverrides: {},
  disableGeoRedirect: false,
  singleDomainMode:
    typeof process.env.REACT_APP_SINGLE_DOMAIN_MODE === 'undefined'
      ? true
      : process.env.REACT_APP_SINGLE_DOMAIN_MODE, // default to true if this env var is not set
  schemaExtensions: [],
  preserveRedirect: true,
  structuredData: {
    disableDefaultProductData: false
  },
  openGraph: {
    disableDefaultProductData: false
  },
  trackers: [],
  serverTrackers: [
    createFacebookTracker({
      pixelId: process.env.FACEBOOK_PIXEL_ID,
      token: process.env.FACEBOOK_CAPI_TOKEN
    })
  ],
  googleMapsApiKey:
    process.env.REACT_APP_GOOGLE_MAPS_API_KEY ||
    'AIzaSyAscR_WznLtQN6SswBsguZnSZTXl0sVH5M',
  loginPath: '/login',
  pathsWithNoAuthRequired: [
    '/login',
    '/reset-password',
    '/forgot-password',
    '/customer-service-1/contact-us-1',
    '/fa-hjelp/kontakt-oss',
    '/tarvitsetko-apua/ota-yhteytta',
    '/de-laide/contactez-nous'
  ],
  preload: {
    routeQuery,
    preloadComponents: {
      Product: [ProductPage],
      Category: [LoadableCategoryPage]
    }
  }
};
export default config;
