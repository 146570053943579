import SubNavigationQuery from './SubNavigationQuery.gql';
import { useQuery } from '@apollo/react-hooks';
import useHeaderHeight from '../../../hooks/useHeaderHeight';
import t from '@jetshop/intl';
import { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Caret } from '../../../../svg/angle-right.svg';
import { theme } from '../../../Theme';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { PopupContent } from '../../../ui/PopupContent';
import { GridChannelSelector } from '../ChannelSelector/GridChannelSelector';
import CategoryLink from '@jetshop/ui/CategoryLink';
import useChannelCheck from '../../../hooks/useChannelCheck';

const Scroll = styled('div')`
  flex: 1 1 auto;
`;

const NewDrawer = styled('nav')`
  background: white;
  position: fixed;
  display: block;
  top: ${props => props.topPosition}px;
  height: calc(100vh - ${props => props.topPosition}px);
  z-index: 4;
  max-width: 100%;
  ${theme.below.lg} {
    max-width: 90%;
  }

  width: ${props => props.size}px;
  transform: translateX(${props => (props.isOpen ? 0 : props.size)}px);
  transition: transform 0.2s ease-in-out;
  right: 0;

  &.left {
    left: 0;
    right: auto;
    transform: translateX(${props => (props.isOpen ? 0 : -props.size)}px);
  }
`;
const MenuWrapper = styled('div')`
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
  opacity: ${props => (props.active ? 1 : 0)};
  overflow-y: ${props => (props.active ? 'auto' : 'hidden')};
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  transform: translateX(${props => (props.active ? 0 : -100)}%);
  transition: ${props => (props.active ? 0.4 : 0.2)}s ease-in-out;
  padding: 5px 20px 0 20px;

  .categories {
    span,
    a,
    button {
      text-transform: capitalize;
    }
  }
  .caps {
    text-transform: capitalize;
    &.show-all {
      font-weight: bold;
    }
  }

  &.toplevel {
    a,
    button {
      text-transform: none;
    }
  }
  &.submenu {
    .categories {
      padding-bottom: 5px;
    }
    a,
    span {
      font-size: 14px;
      border: 0;
    }
    a,
    button {
      padding: 15px 0;
      height: auto;
      line-height: 1;
      border: 0;
      border-bottom: 1px solid #f5f5f5;
    }
    button.backbutton {
      margin-bottom: 6px;
      border-bottom: 1px solid #ddd;
      padding: 18px 0 16px 0;
      text-transform: capitalize;
      span {
        font-size: 14px;
        &.small {
          font-size: 14px;
        }
      }
    }
  }

  button,
  a {
    svg,
    img {
      width: 1rem;
      object-fit: contain;
    }
    span {
      font-size: 14px;
      svg,
      img {
        width: 1.5rem;
        max-height: 1rem;
        margin-right: 0.5rem;
      }
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 14px 0;
    text-align: left;
    border-bottom: 1px solid #f7f7f7;
    color: black;
    text-decoration: none;
    font-size: 14px;
    letter-spacing: 0.5px;
    width: 100%;
    span {
      display: flex;
      align-items: center;
    }
    &.red {
      color: ${theme.colors.red};
    }

    &:focus {
      outline: none;
    }
    &:active {
      background: #f9f9f9;
    }
  }
  .backbutton {
    svg {
      transform: rotate(-180deg);
      margin-top: 1px;
      margin-right: 2px;
    }
  }
`;

const SubMenu = styled('div')`
  background: #f7f7f7;
  margin-left: -20px;
  margin-right: -20px;
  height: 100%;
  padding: 10px 20px;

  &.menu-submenu {
    padding-bottom: 100px;
    .custom-submenu-toggle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid #ececec;
      h4 {
        text-transform: capitalize;
      }

      svg.active {
        transform: rotate(90deg);
      }
    }

    ul li {
      border-bottom: 1px solid #ececec;
    }

    a,
    h4 {
      margin: 0;
      text-transform: none;
      background: #f7f7f7;
      padding: 10px 0;
      font-size: 14px;
      font-weight: normal;
      font-family: ${theme.fonts.primary};
      color: #4a4a4a;
      text-transform: capitalize;
    }
  }
`;

const CountrySelectWrapper = styled('div')`
  cursor: pointer;
  display: flex;
  height: fit-content;
  span {
    margin-left: 2px;
  }

  .country-select {
    margin-right: 20px;
    padding-bottom: 0;
    display: flex;
    align-items: center;
  }

  svg {
    align-self: center;
    transform: rotate(90deg);
    margin-left: 10px;
    margin-top: 2px;
  }

  ${theme.below.lg} {
    align-items: flex-end;
  }
`;

const CountryFlag = styled('img')`
  height: auto;
  width: 32px;
  margin-right: 5px;
`;

export const CountryPicker = ({
  countrySelectOpen,
  countryCode,
  setCountrySelectOpen,
  countryName,
  selectedChannel,
  currency
}) => {
  const handleCountrySelectTapped = () => {
    setCountrySelectOpen(!countrySelectOpen);
  };

  return (
    <CountrySelectWrapper onClick={handleCountrySelectTapped}>
      <h4 className="country-select">
        {selectedChannel.id === 13 ? (
          <CountryFlag
            src={`https://countryflags.jetshop.io/gb/flat/32.png`}
            alt={t('Country Flag')}
          />
        ) : (
          <CountryFlag
            src={`https://countryflags.jetshop.io/${countryName}/flat/32.png`}
            alt={t('Country Flag')}
          />
        )}
        <span>
          {countryName} ({currency})
        </span>
      </h4>
      <Caret />
    </CountrySelectWrapper>
  );
};

const MenuLevel = ({
  countryName,
  countryCode,
  currency,
  countrySelectOpen,
  setCountrySelectOpen,
  categories,
  active,
  goBack = () => {},
  parent = null,
  subData,
  selectedChannel,
  ...rest
}) => {
  const { isB2B } = useChannelCheck();
  const [activeId, setActiveId] = useState(null);
  const [activeSubmenu, setActiveSubmenu] = useState(false);
  const backHandler = () => {
    setActiveId(null);
  };

  useEffect(() => {
    if (!rest.isOpen && activeId) {
      //To avoid flicker on close
      setTimeout(() => {
        setActiveId(null);
      }, 300);
    }
  }, [rest.isOpen, activeId]);

  return (
    <>
      {active && (
        <>
          <MenuWrapper
            active={active}
            className={parent ? 'submenu' : 'toplevel'}
          >
            <div className="categories">
              {parent && (
                <button onClick={goBack} className="backbutton">
                  <span className="caps">{parent.name.toLowerCase()}</span>
                  <span className="small">
                    <Caret />
                    {parent.parent ? (
                      <>
                        {t('Back to ')}
                        {parent.parent.name.toLowerCase()}
                      </>
                    ) : (
                      t('Back')
                    )}
                  </span>
                </button>
              )}
              {parent && (
                <Link onClick={rest.close} to={parent.primaryRoute.path}>
                  <span className="caps show-all">
                    <strong>
                      {t('Show all')} {parent.name.toLowerCase()}
                    </strong>
                  </span>
                </Link>
              )}
              {categories.map(cat =>
                cat.hasSubcategories && cat?.subcategories ? (
                  <button
                    key={`btn-${cat.id}`}
                    onClick={() => setActiveId(cat.id)}
                  >
                    <span className={cat?.parent?.id ? 'caps' : ''}>
                      {cat.name.toLowerCase()}
                    </span>
                    <Caret />
                  </button>
                ) : (
                  <React.Fragment key={cat.id}>
                    <Link
                      onClick={rest.close}
                      to={cat.primaryRoute.path}
                      key={`link-${cat.id}`}
                    >
                      <span className={cx(!parent ? 'caps' : '')}>
                        {cat.name.toLowerCase()}
                      </span>
                    </Link>
                  </React.Fragment>
                )
              )}
            </div>

            {!parent && (
              <SubMenu className="menu-submenu">
                <div
                  className="custom-submenu-toggle"
                  onClick={() => setActiveSubmenu(!activeSubmenu)}
                >
                  <h4>{subData?.category?.name.toLowerCase()}</h4>
                  <Caret className={activeSubmenu ? 'active' : ''} />
                </div>
                <ul>
                  {activeSubmenu && (
                    <>
                      {subData?.category?.subcategories.map(
                        subcat =>
                          subcat.name !== 'Contact' && (
                            <li key={subcat.id}>
                              <CategoryLink
                                key={subcat.id}
                                category={subcat}
                                onClick={() => {
                                  rest.close();
                                  setActiveSubmenu(false);
                                }}
                              >
                                {subcat?.name.toLowerCase()}
                              </CategoryLink>
                            </li>
                          )
                      )}
                    </>
                  )}
                  <li>
                    {isB2B ? (
                      <a href="/?culture=en-GB&currency=EUR&channelCountry=NO&channel=B2C-EU">
                        {t('B2C Shop')}
                      </a>
                    ) : (
                      <a href="https://finntack.com/b2beu?redirectDisabled=true">
                        {t('B2B Login')}
                      </a>
                    )}
                  </li>
                  <li>
                    <Link to="/finntack-shops">{t('Finntack shops')}</Link>
                  </li>
                  <li>
                    <Link to="/customer-service-1/contact-us-1" href="#">
                      {t('Customer Service')}
                    </Link>
                  </li>
                  <li onClick={rest.close}>
                    <CountryPicker
                      selectedChannel={selectedChannel}
                      countryName={countryCode}
                      countryCode={countryCode}
                      countrySelectOpen={countrySelectOpen}
                      setCountrySelectOpen={setCountrySelectOpen}
                      currency={currency}
                    />
                  </li>
                </ul>
              </SubMenu>
            )}
          </MenuWrapper>

          {categories?.map(cat =>
            cat.hasSubcategories && cat?.subcategories ? (
              <MenuLevel
                key={`cat-${cat.id}`}
                {...rest}
                parent={{ ...cat, parent: parent }}
                goBack={backHandler}
                categories={cat.subcategories}
                active={cat.id === activeId}
                selectedChannel={selectedChannel}
              />
            ) : null
          )}
        </>
      )}
    </>
  );
};

export const PositionedDrawer = ({ isOpen, size = 320, children, left }) => {
  const topPosition = useHeaderHeight();
  return (
    <NewDrawer
      topPosition={topPosition}
      isOpen={isOpen}
      size={size}
      className={left ? 'left' : ''}
    >
      {children}
    </NewDrawer>
  );
};

export default function FlyoutMenu({ categoryData, size = 320, left = false }) {
  const { data } = useQuery(SubNavigationQuery, {
    variables: {
      id: 215
    }
  });

  const [countrySelectOpen, setCountrySelectOpen] = useState(false);
  const { channels, selectedChannel, updateChannel } = useContext(
    ChannelContext
  );

  if (!(categoryData && categoryData.categories)) return null;
  return (
    <>
      <DrawerTarget id="menu-drawer">
        {drawer => (
          <PositionedDrawer isOpen={drawer.isOpen} size={size} left={left}>
            <Scroll>
              <MenuLevel
                countryName={selectedChannel?.country?.name}
                countryCode={selectedChannel?.name.split('-')[1]}
                currency={selectedChannel?.defaultCurrency.name}
                countrySelectOpen={countrySelectOpen}
                setCountrySelectOpen={setCountrySelectOpen}
                isOpen={drawer.isOpen}
                close={drawer.hideTarget}
                categories={categoryData.categories}
                active={true}
                subData={data}
                selectedChannel={selectedChannel}
              />
            </Scroll>
          </PositionedDrawer>
        )}
      </DrawerTarget>
      <PopupContent open={countrySelectOpen} setOpen={setCountrySelectOpen}>
        <GridChannelSelector
          channels={channels}
          selectedChannel={selectedChannel}
          updateChannel={updateChannel}
        />
      </PopupContent>
    </>
  );
}
